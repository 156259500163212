// import './front/accordion.js';
// import './front/aos.js';
// import './front/browserizr.js';
// import './front/external-links.js';
// import './front/leaflet-map.js';
// import './front/magnific-popup.js';
// import './front/menu-offcanvas.js';
// import './front/menu-sticky.js';
// import './front/menu-sub.js';
// import './front/outdated-browser.js';
// import './front/pagination.js';
// import './front/photoswipe.js';
// import './front/recaptcha.js';
// import './front/scroll-to.js';
// import './front/slick.js';
// import './front/vhcheck.js';

const htmlElement = document.querySelector('html');
htmlElement.classList.remove('no-js');
htmlElement.classList.add('js');
